<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout class="custom-header">
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Serial Numbers
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="position: relative"
    >
      <template>
        <CustomerBarcode> </CustomerBarcode>
      </template>
      <template>
        <PropertyBarcode> </PropertyBarcode>
      </template>
      <template>
        <SupplierBarcode> </SupplierBarcode>
      </template>
      <!--   <template>
        <QuotationBarcode> </QuotationBarcode>
      </template> -->
      <template>
        <ContractBarcode> </ContractBarcode>
      </template>
      <!--    <template>
        <InvoiceBarcode> </InvoiceBarcode>
      </template> -->
      <!--   <template>
        <PaymentBarcode> </PaymentBarcode>
      </template> -->
      <template>
        <TechniciansBarcode> </TechniciansBarcode>
      </template>
      <!--   <template>
        <LeaveBarcode> </LeaveBarcode>
      </template> -->
      <template>
        <ProductBarcode> </ProductBarcode>
      </template>
      <template>
        <ServiceBarcode> </ServiceBarcode>
      </template>
      <template>
        <DeliveryBarcode> </DeliveryBarcode>
      </template>
      <!--   <template>
        <IncidentReportBarcode> </IncidentReportBarcode>
      </template> -->
    </perfect-scrollbar>
  </v-container>
</template>

<script>
/* import { GET, PATCH } from "@/core/services/store/request.module"; */
import CustomerBarcode from "@/view/pages/profile/Setting/Partial/Customer-barcode";
import PropertyBarcode from "@/view/pages/profile/Setting/Partial/Property-barcode";
import SupplierBarcode from "@/view/pages/profile/Setting/Partial/Supplier-barcode";
//import QuotationBarcode from "@/view/pages/profile/Setting/Partial/Quotation-barcode";
import ContractBarcode from "@/view/pages/profile/Setting/Partial/Contract-barcode";
/* import InvoiceBarcode from "@/view/pages/profile/Setting/Partial/Invoice-barcode";
import PaymentBarcode from "@/view/pages/profile/Setting/Partial/Payment-barcode"; */
import TechniciansBarcode from "@/view/pages/profile/Setting/Partial/Technicians-barcode";
/* import LeaveBarcode from "@/view/pages/profile/Setting/Partial/Leave-barcode"; */
import ProductBarcode from "@/view/pages/profile/Setting/Partial/Product-barcode";
import ServiceBarcode from "@/view/pages/profile/Setting/Partial/Service-barcode";
import DeliveryBarcode from "@/view/pages/profile/Setting/Partial/Delivery-barcode";
/* import IncidentReportBarcode from "@/view/pages/profile/Setting/Partial/IncidentReport-barcode"; */

export default {
  name: "profile-customer-setting",
  data() {
    return {
      pageLoading: true,
    };
  },
  methods: {},
  mounted() {},
  components: {
    CustomerBarcode,
    PropertyBarcode,
    SupplierBarcode,
    /*  QuotationBarcode, */
    ContractBarcode,
    TechniciansBarcode,
    /*  LeaveBarcode, */
    ProductBarcode,
    ServiceBarcode,
    DeliveryBarcode,
    /*  IncidentReportBarcode,
    InvoiceBarcode,
    PaymentBarcode, */
    /* ContactPersons */
  },
};
</script>
