<template>
  <v-container fluid class="" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Module Setting
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Hide Module
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateServiceFormSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr>
                    <v-switch
                      v-model="allow_stamp_note"
                      class="mt-0"
                      inset
                      label="Preventive Maintenances"
                      color="green"
                    ></v-switch>
                  </tr>
                  <tr>
                    <v-switch
                      v-model="allow_service_form"
                      class="mt-0"
                      inset
                      label="Enquiry"
                      color="green"
                    ></v-switch>
                  </tr>
                  <tr>
                    <v-switch
                      v-model="allow_voice_note"
                      :readonly="!allow_service_form"
                      class="mt-0"
                      inset
                      label="Leave"
                      color="green"
                    ></v-switch>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { /*  GET,  */ PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-job-setting",
  data() {
    return {
      pageLoading: false,
      setting: {
        prefix: null,
        client_remark: null,
        next_number: null,
        number_length: null,
        buffer_time: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        job_type: [],
      },
      allow_service_form: false,
      allow_voice_note: false,
      allow_stamp_note: false,
      app_setting: [],

      jobTypeList: [
        {
          text: null,
        },
      ],
    };
  },
  components: {},
  methods: {
    getText(text) {
      if (text == "allow_creatation") {
        return "Visit Creation Allow In Application";
      } else if (text == "self_allow") {
        return "Self";
      } else if (text == "all_technician") {
        return "All technician";
      }
    },

    updateJobAppSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/job-app",
          data: {
            app_setting: this.app_setting,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateServiceFormSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/job-service-app",
          data: {
            allow_service_form: this.allow_service_form ? 1 : 0,
            allow_stamp_note: this.allow_stamp_note ? 1 : 0,
            allow_voice_note: this.allow_voice_note ? 1 : 0,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    /*    this.getJobSetting(); */
  },
};
</script>
