<template>
  <v-container fluid class="width-100" v-if="getPermission('setting:view')">
    <v-card
      flat
      class="custom-setting-height custom-grey-border remove-border-radius"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Product #
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" class="text-right py-0">
              <v-btn
                depressed
                small
                class="text-white"
                color="cyan"
                v-on:click="updateProductSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <v-card-text
        class="p-6 font-size-16"
        :class="{ 'pointer-events-none': !getPermission('setting:update') }"
      >
        <v-row>
          <v-col md="4" class="pb-4">
            <label>Prefix</label>
            <v-text-field
              v-model.trim="setting.prefix"
              dense
              filled
              placeholder="Prefix"
              solo
              flat
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="pb-4">
            <label>Next Number</label>
            <v-text-field
              v-model.trim="setting.next_number"
              dense
              filled
              placeholder="Next Number"
              solo
              flat
              type="number"
              min="0"
              max="999"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="pb-4">
            <label>Number Length</label>
            <v-text-field
              v-model.trim="setting.number_length"
              dense
              filled
              placeholder="Number Length"
              solo
              flat
              type="number"
              min="0"
              max="15"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="12" class="pb-4" v-if="false">
            <table class="width-100">
              <tr>
                <td class="py-2">
                  When you want to hold stock
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2" v-bind="attrs" v-on="on"
                        >mdi-progress-question</v-icon
                      >
                    </template>
                    <span
                      >Default tax rate will appear<br />on new invoices, jobs
                      and quotations</span
                    >
                  </v-tooltip>
                </td>
                <td class="py-2">
                  <v-radio-group
                    class="my-0"
                    hide-details
                    row
                    mandatory
                    v-model="setting.hold_stock_level"
                  >
                    <v-radio
                      v-for="(row, index) in holdStockList"
                      :key="index"
                      :label="row.text"
                      :value="row.value"
                      :disabled="pageLoading"
                      color="cyan"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td class="py-2">
                  When you want to release stock
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2" v-bind="attrs" v-on="on"
                        >mdi-progress-question</v-icon
                      >
                    </template>
                    <span
                      >Default tax rate will appear<br />on new invoices, jobs
                      and quotations</span
                    >
                  </v-tooltip>
                </td>
                <td class="py-2">
                  <v-radio-group
                    class="my-0"
                    hide-details
                    row
                    mandatory
                    v-model="setting.release_stock_level"
                  >
                    <v-radio
                      v-for="(row, index) in releaseStockList"
                      :key="index"
                      :label="row.text"
                      :value="row.value"
                      :disabled="pageLoading"
                      color="cyan"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td class="py-2">
                  Accept overstock transactions
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2" v-bind="attrs" v-on="on"
                        >mdi-progress-question</v-icon
                      >
                    </template>
                    <span
                      >Default tax rate will appear<br />on new invoices, jobs
                      and quotations</span
                    >
                  </v-tooltip>
                </td>
                <td class="py-2">
                  <v-radio-group
                    class="my-0"
                    hide-details
                    row
                    mandatory
                    v-model="setting.accept_over_stock"
                  >
                    <v-radio
                      label="Yes"
                      :value="1"
                      :disabled="pageLoading"
                      color="cyan"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                      :disabled="pageLoading"
                      color="cyan"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      flat
      class="custom-grey-border remove-border-radius mt-4"
      v-if="false"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Product Description
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <!-- <v-col md="6" class="text-right py-0">
                  <v-btn
                    small
                    class="text-white"
                    color="cyan"
                    v-on:click="updateProductSetting"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Update</span>
                  </v-btn>
                </v-col> -->
          </template>
        </v-row>
      </v-card-title>
      <v-card-text
        class="p-6 font-size-16"
        :class="{ 'pointer-events-none': !getPermission('setting:update') }"
      >
        <v-row>
          <v-col md="12" class="pb-4">
            <v-textarea
              v-model.trim="setting.description"
              auto-grow
              dense
              filled
              placeholder="Description"
              solo
              flat
              :disabled="pageLoading"
              :loading="pageLoading"
              row-height="30"
              color="cyan"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-product-setting",
  data() {
    return {
      pageLoading: true,
      holdStockList: [
        {
          value: "quotation",
          text: "Quotation",
        },
        {
          value: "job",
          text: "Job",
        },
        {
          value: "none",
          text: "None",
        },
      ],
      releaseStockList: [
        {
          value: "job",
          text: "Job",
        },
        {
          value: "invoice",
          text: "Invoice",
        },
        {
          value: "none",
          text: "None",
        },
      ],
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        description: null,
      },
    };
  },
  methods: {
    getProductSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/product" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateProductSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/product", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getProductSetting();
  },
};
</script>
