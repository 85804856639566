<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Time Slots
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline sticky-header grey lighten-4">
          <v-row>
            <v-col md="2" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Slots #
              </h3>
            </v-col>
            <template>
              <v-col md="10" class="text-right py-0">
                <div class="d-flex justify-content-end">
                  <v-btn
                    depressed
                    small
                    class="text-white mr-5"
                    color="cyan"
                    v-on:click="AddLeave"
                    :disabled="pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Add</span>
                  </v-btn>
                  <v-btn
                    depressed
                    small
                    class="text-white"
                    color="cyan"
                    v-on:click="updateOrcreate"
                    :disabled="pageLoading || !formValid"
                  >
                    <span class="font-size-16 font-weight-600">Save</span>
                  </v-btn>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-form
            ref="time_slote"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrcreate"
          >
            <v-row>
              <v-col md="12" class="pb-4">
                <ListingTable
                  :columnCount="4"
                  :dataLoading="pageLoading"
                  :rowData="timeSlotes"
                >
                  <template v-slot:thead>
                    <thead style="position: sticky; top: 0px">
                      <tr>
                        <th class="simple-table-th">Start Time</th>
                        <th class="simple-table-th">End Time</th>
                        <th class="simple-table-th">Slote</th>
                        <th class="simple-table-th">Active/In Active</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody class="custom-border-bottom">
                      <template v-if="timeSlotes.length">
                        <tr v-for="(type, index) in timeSlotes" :key="index">
                          <td class="simple-table-td">
                            <TimePicker
                              v-on:change="getSlote(type, index)"
                              :disabled="type.activated ? false : true"
                              key="visit-schedule-end-time"
                              id="schedule-end-time"
                              placeholder="Start Time"
                              v-model="type.start"
                            >
                            </TimePicker>
                          </td>
                          <td class="simple-table-td">
                            <TimePicker
                              key="visit-schedule-end-time"
                              id="schedule-end-time"
                              v-on:change="getSlote(type, index)"
                              placeholder="End Time"
                              v-model="type.end"
                              :min-time="type.start"
                              :disabled="type.activated ? false : true"
                            >
                            </TimePicker>
                          </td>
                          <td class="simple-table-td">
                            <v-text-field
                              v-model.trim="type.slotes"
                              dense
                              filled
                              :disabled="type.activated ? false : true"
                              class="textCapitalize"
                              readonly
                              placeholder="Slote"
                              solo
                              flat
                              hide-details
                              color="cyan"
                            ></v-text-field>
                          </td>
                          <td class="simple-table-td">
                            <v-switch
                              class="my-2 p-0"
                              color="cyan"
                              v-model="type.activated"
                              inset
                              hide-details
                            ></v-switch>
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td :colspan="5" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no country at the moment.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </ListingTable>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { POST, /* DELETE, */ GET } from "@/core/services/store/request.module";
/*   import { map } from "lodash"; */
import ValidationMixin from "@/core/plugins/validation-mixin";
import TimePicker from "@/view/components/TimePicker.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
/* import {concat } from "lodash"; */
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "timeslote-setting",
  data() {
    return {
      pageLoading: true,
      formValid: true,
      period_type: "half",
      payload: null,
      timeSlotes: [
        {
          start: null,
          end: null,
          slotes: null,
          activated: 1,
        },
      ],
    };
  },
  methods: {
    getSlote(slote, index) {
      if (slote.start && slote.end) {
        let start = slote.start;
        let end = slote.end;
        this.timeSlotes[index].slotes = `${start} - ${end}`;
      }
    },
    updateOrcreate() {
      const _this = this;
      if (!_this.$refs.time_slote.validate()) {
        return false;
      }
      /*  this.payload =   this.lodash.filter(this.timeSlotes, {
        activated: 1,
      });
      console.log(this.payload,'this.timeSlotes') */
      _this.pageLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "setting/timeslote",
          data: { categories: _this.timeSlotes },
        })
        .then(() => {
          this.getTimeSlotes();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    AddLeave() {
      this.timeSlotes.push({
        start: null,
        end: null,
        slotes: null,
        activated: 1,
      });
    },

    getTimeSlotes() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "setting/slotes",
        })
        .then(({ data }) => {
          _this.timeSlotes = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },

  mounted() {
    this.getTimeSlotes();
  },
  components: {
    TimePicker,
    ListingTable,
  },
};
</script>
