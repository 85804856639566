<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Service
        </h3>
      </v-flex>
      <div class="">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Skill Certificate
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="py-0">
                <div class="d-flex justify-content-end">
                  <v-checkbox
                    class="mx-4"
                    v-model="show_skills"
                    color="red white--text"
                    label="Show To Technicians & Service Module"
                  />
                  <v-btn
                    depressed
                    small
                    class="mx-4 custom-grey-border custom-bold-button"
                    v-on:click="createProductSkill"
                    :disabled="pageLoading || !show_skills"
                  >
                    <span class="font-size-16 font-weight-600">Create</span>
                  </v-btn>
                  <v-btn
                    depressed
                    small
                    class="text-white"
                    color="cyan"
                    v-on:click="updateProductSkill"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Update</span>
                  </v-btn>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-form
            ref="ticketCollecttionForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateProductSkill"
          >
            <v-row class="py-0">
              <v-col md="12" class="py-0">
                <table class="width-100">
                  <tbody>
                    <tr
                      v-for="(type, index) in skillCertificateList"
                      :key="index"
                    >
                      <td>
                        <v-text-field
                          v-model.trim="type.text"
                          dense
                          filled
                          placeholder="Skill Certificate"
                          solo
                          flat
                          class="px-4 py-2"
                          hide-details
                          :disabled="pageLoading || !show_skills"
                          :loading="pageLoading"
                          :rules="[
                            validateRules.required(
                              type.text,
                              'Skill Certificate'
                            ),
                          ]"
                          color="cyan"
                        ></v-text-field>
                      </td>
                      <td width="50" v-if="skillCertificateList.length > 1">
                        <v-tooltip
                          top
                          content-class="custom-top-tooltip"
                          v-if="type && type.id && type.id > 0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteDbSkill(type)"
                              color="deep-orange"
                              :disabled="!show_licence"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                        <v-tooltip
                          top
                          content-class="custom-top-tooltip"
                          v-else
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteProductSkill(index)"
                              color="deep-orange"
                              v-bind="attrs"
                              :disabled="!show_skills"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                License Certificate
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="py-0">
                <div class="d-flex justify-content-end">
                  <v-checkbox
                    class="mx-4"
                    v-model="show_licence"
                    color="red white--text"
                    label="Show To Technicians & Service Module"
                  />
                  <v-btn
                    depressed
                    small
                    class="mx-4 custom-grey-border custom-bold-button"
                    v-on:click="createProductLicesnse"
                    :disabled="dataLoading || !show_licence"
                  >
                    <span class="font-size-16 font-weight-600">Create</span>
                  </v-btn>
                  <v-btn
                    depressed
                    small
                    class="text-white"
                    color="cyan"
                    v-on:click="updateProductLicesnse"
                    :disabled="dataLoading"
                    :loading="dataLoading"
                  >
                    <span class="font-size-16 font-weight-600">Update</span>
                  </v-btn>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-form
            ref="ticketCollecttionFormNew"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateProductLicesnse"
          >
            <v-row class="py-0">
              <v-col md="12" class="py-0">
                <table class="width-100">
                  <tbody>
                    <tr
                      v-for="(type, index) in licenseCertificateList"
                      :key="index"
                    >
                      <td>
                        <v-text-field
                          v-model.trim="type.text"
                          dense
                          filled
                          placeholder="License Certificate"
                          solo
                          flat
                          class="px-4 py-2"
                          hide-details
                          :disabled="dataLoading || !show_licence"
                          :loading="dataLoading"
                          :rules="[
                            validateRules.required(
                              type.text,
                              'License Certificate'
                            ),
                          ]"
                          color="cyan"
                        ></v-text-field>
                      </td>
                      <td width="50" v-if="licenseCertificateList.length > 1">
                        <v-tooltip
                          top
                          content-class="custom-top-tooltip"
                          v-if="type && type.id && type.id > 0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteDbLicense(type)"
                              color="deep-orange"
                              :disabled="!show_licence"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                        <v-tooltip
                          top
                          content-class="custom-top-tooltip"
                          v-else
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteProductLicesnse(index)"
                              color="deep-orange"
                              :disabled="!show_licence"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [ValidationMixin],
  name: "profile-service-setting",
  data() {
    return {
      formValid: true,
      skillCertificateList: [
        {
          id: null,
          value: null,
          text: null,
        },
      ],
      licenseCertificateList: [
        {
          id: null,
          value: null,
          text: null,
        },
      ],
      show_skills: true,
      show_licence: true,
      dataLoading: false,
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        description: null,
      },
    };
  },
  methods: {
    updateProductLicesnse() {
      const _this = this;
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormNew
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.dataLoading = false;
        }
        return false;
      }
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/service-licence",
          data: {
            license_skill: _this.licenseCertificateList,
            show_licence: this.show_licence ? 1 : 0,
          },
        })
        .then(() => {
          _this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    createProductLicesnse() {
      this.licenseCertificateList.push({
        value: null,
        text: null,
      });
    },
    deleteProductLicesnse(index) {
      if (this.licenseCertificateList.length > 1) {
        this.licenseCertificateList.splice(index, 1);
      }
    },
    updateProductSkill() {
      const _this = this;
      const formErrors = _this.validateForm(_this.$refs.ticketCollecttionForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.pageLoading = false;
        }
        return false;
      }
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/service-skills",
          data: {
            service_skill: _this.skillCertificateList,
            show_skills: this.show_skills ? 1 : 0,
          },
        })
        .then(() => {
          _this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createProductSkill() {
      this.skillCertificateList.push({
        text: null,
        value: null,
      });
    },
    deleteProductSkill(index) {
      if (this.skillCertificateList.length > 1) {
        this.skillCertificateList.splice(index, 1);
      }
    },
    deleteDbLicense(type) {
      const _this = this;
      _this.$store
        .dispatch(DELETE, { url: `service-license/${type.value}/${type.id}` })
        .then(() => {
          this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    deleteDbSkill(type) {
      const _this = this;
      _this.$store
        .dispatch(DELETE, { url: `service-skill/${type.value}/${type.id}` })
        .then(() => {
          this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getServiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/service" })
        .then(({ data }) => {
          _this.setting = data;
          if (
            Array.isArray(data.skillCertificate) &&
            data.skillCertificate.length
          ) {
            _this.skillCertificateList = data.skillCertificate;
          }
          if (
            Array.isArray(data.licenseCertificate) &&
            data.licenseCertificate.length
          ) {
            _this.licenseCertificateList = data.licenseCertificate;
          }
          _this.show_skills = data.show_skills;
          _this.show_licence = data.show_licence;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateServiceSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/service", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },

  mounted() {
    this.getServiceSetting();
    this.$refs.ticketCollecttionForm.validate();
    this.$refs.ticketCollecttionFormNew.validate();
  },
};
</script>
